import React from "react"

import SEO from "../components/Seo"

const ContactPage = () => (
  <>
    <SEO title="Contact" />
    <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
  </>
)

export default ContactPage
